.person_details {
    margin-bottom: 20px;
}

.person_details h4{
    margin-bottom: 20px;
}

.person_details p {
    font-size: 18px;
    font-weight: bold;
}
.person_details p span {
    color:#999;
    font-size: 12px;
    font-weight: normal;
}

.person_history .person_time {
    font-size: 17px;
    color:#aaa;
}

.person_history .person_time svg {
    height: 20px;
    width: 20px;
    margin-bottom:-3px;
}

.person_history .person_time_role {
    font-weight: bold;
    font-size:19px;
}

.person_history .person_time_role span {
    color:#D70B0B;
}
