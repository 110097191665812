.roleHolder {
    font-size: 0.7rem !important;
    margin-bottom: 3px !important;
}

.parishHolder{
    font-size: 0.7rem !important;
    font-weight: bold !important;
    margin-bottom: 3px !important;
}


.parishHolder svg, .contactHolder svg{
    width:0.6em;
    height:0.6em;
    margin-right: 4px;
}

.contactHolder {
    font-size: 0.7rem !important;
    margin-bottom: 3px !important;
}

.personHolder{
    border-left: 4px solid #D70B0B;
    margin-bottom: 3px !important;
    min-height: 10rem;
}